<template>
  <vant-form ref="form">
    <div class="field-list">
      <vant-field
        v-model="main.form.subject_info.type"
        label="商户类型"
        readonly
      ></vant-field>
      <vant-field
        v-model="main.form.settlement_info.qualification_type"
        label="经营类目"
        placeholder="请选择"
        clickable
        is-link
        readonly
        :rules="[{ required: true, message: '请选择经营类目' }]"
        @click="showCategoryPanel = true"
      ></vant-field>
      <p class="message">{{ category_rate_message }}</p>
      <template
        v-if="
          [
            subject_type_list.SUBJECT_TYPE_INDIVIDUAL,
            subject_type_list.SUBJECT_TYPE_ENTERPRISE,
          ].includes(main.type)
        "
      >
        <vant-field
          name="uploader"
          label="营业执照照片"
          class="uploader-field"
          :rules="[{ required: true, message: '请上传营业执照照片' }]"
        >
          <template #input>
            <vant-uploader
              v-model="
                main.form.subject_info.business_license_info.license_copy
              "
              :max-count="1"
              :after-read="handleLicenseRead"
            >
              <div class="uploader-input-wrapper">
                <vant-icon name="plus" />
              </div>
            </vant-uploader>
          </template>
        </vant-field>
        <p class="message">
          必须为彩色图片且小于2M，文件格式为bmp、png、jpeg、jpg或gif
        </p>
      </template>

      <template
        v-if="
          [
            subject_type_list.SUBJECT_TYPE_INDIVIDUAL,
            subject_type_list.SUBJECT_TYPE_ENTERPRISE,
          ].includes(main.type)
        "
      >
        <vant-field
          v-model="main.form.subject_info.business_license_info.legal_person"
          label="经营者姓名"
          :rules="[{ required: true, message: '请填写经营者姓名' }]"
          @input="handleLegalPersonChange"
        ></vant-field>
        <p class="message">请填写营业执照的经营者/法定代表人姓名。</p>
      </template>
      <vant-field
        name="uploader"
        label="特殊资质图片"
        class="uploader-field"
        v-if="main.form.settlement_info.qualification_type"
        :rules="[{ required: true, message: '请上传特殊资质图片' }]"
      >
        <template #input>
          <vant-uploader
            v-model="main.form.settlement_info.qualifications"
            :max-count="5"
          >
            <div class="uploader-input-wrapper">
              <vant-icon name="plus"></vant-icon>
            </div>
          </vant-uploader>
        </template>
      </vant-field>
      <p class="message" v-if="main.form.settlement_info.qualification_type">
        {{ qulification_message }}
      </p>
      <template
        v-if="
          [
            subject_type_list.SUBJECT_TYPE_INDIVIDUAL,
            subject_type_list.SUBJECT_TYPE_ENTERPRISE,
          ].includes(main.type)
        "
      >
        <vant-field
          v-model="main.form.subject_info.business_license_info.license_number"
          label="注册号"
          :rules="[{ required: true, message: '请填写注册号' }]"
        ></vant-field>
        <p class="message">
          请填写营业执照上的营业执照注册号，注册号格式须为15位数字或18位数字|大写字母。
        </p>
      </template>
      <template
        v-if="
          [
            subject_type_list.SUBJECT_TYPE_INDIVIDUAL,
            subject_type_list.SUBJECT_TYPE_ENTERPRISE,
          ].includes(main.type)
        "
      >
        <vant-field
          v-model="main.form.subject_info.business_license_info.merchant_name"
          label="商户名称"
          :rules="[{ required: true, message: '请填写商户名称' }]"
        ></vant-field>
        <p class="message">
          1、请填写营业执照上的商户名称，2~110个字符，支持括号。
          2、个体户，不能以“公司”结尾。
          3、个体户，若营业执照上商户名称为“空“或“无”时，填写"个体户+经营者姓名"，如“个体户张三”。
        </p>
      </template>
      <template
        v-if="
          [
            subject_type_list.SUBJECT_TYPE_INSTITUTIONS,
            subject_type_list.SUBJECT_TYPE_OTHERS,
          ].includes(main.type)
        "
      >
        <vant-field
          v-model="main.form.subject_info.certificate_info.merchant_name"
          label="商户名称"
          :rules="[{ required: true, message: '请填写商户名称' }]"
        >
        </vant-field>
        <p class="message">请填写登记证书上的商户名称</p>
      </template>

      <vant-field
        v-model="main.form.business_info.merchant_shortname"
        label="商户简称"
        :rules="[{ required: true, message: '请填写商户简称' }]"
      ></vant-field>
      <p class="message">商户简称将用于在付款页向顾客展示</p>
      <template
        v-if="
          [
            subject_type_list.SUBJECT_TYPE_INSTITUTIONS,
            subject_type_list.SUBJECT_TYPE_OTHERS,
          ].includes(main.type)
        "
      >
        <vant-field
          name="uploader"
          :label="
            main.type === subject_type_list.SUBJECT_TYPE_INSTITUTIONS
              ? '事业单法人证书'
              : '民办非企业单位登记证书'
          "
          class="uploader-field"
        >
          <template #input>
            <vant-uploader
              v-model="main.form.subject_info.certificate_info.cert_copy"
              :max-count="1"
              :after-read="handleLicenseRead"
            >
              <div class="uploader-input-wrapper">
                <vant-icon name="plus" />
              </div>
            </vant-uploader>
          </template>
        </vant-field>
        <p class="message">
          需年检章齐全（当年成立公司除外）；
          必须为彩色图片且小于2M，文件格式为bmp、png、jpeg、jpg或gif
        </p>
        <vant-field
          label="证书号"
          v-model="main.form.subject_info.certificate_info.cert_number"
          :rules="[{ required: true, message: '请填写证书号' }]"
        ></vant-field>
        <p class="message">请填写登记证书上的证书编号</p>
        <vant-field
          label="注册地址"
          :rows="1"
          autoszie
          type="textarea"
          placeholder="请填写登记证书的注册地址"
          v-model="main.form.subject_info.certificate_info.company_address"
          :rules="[{ required: true, message: '请填写注册地址' }]"
        ></vant-field>
        <vant-field
          label="法人姓名"
          v-model="main.form.subject_info.certificate_info.legal_person"
          :rules="[{ required: true, message: '请填写法人姓名' }]"
        ></vant-field>
        <vant-field
          label="有效期限开始日期"
          readonly
          clickable
          name="datetimePicker"
          :value="main.form.subject_info.certificate_info.period_begin"
          :rules="[{ required: true, message: '请选择有效期限开始日期' }]"
        ></vant-field>
        <vant-field
          label="有效期限结束日期"
          readonly
          clickable
          name="datetimePicker"
          :value="main.form.subject_info.certificate_info.period_end"
          :rules="[{ required: true, message: '请选择有效期限结束日期' }]"
        ></vant-field>
      </template>
      <vant-field
        label="门店名称"
        v-model="
          main.form.business_info.sales_info.biz_store_info.biz_store_name
        "
        :rules="[{ required: true, message: '请填写门店名称' }]"
      ></vant-field>
      <vant-field
        type="tel"
        v-model="main.form.business_info.service_phone"
        label="客服电话"
        :rules="[
          { required: true, message: '请填写客服电话' },
          {
            pattern: /^[0-9]{1,  11}$/,
            message: '客服电话格式不对, 请输入输入'
          },
        ]"
      ></vant-field>
      <p class="message">
        1、请填写真实有效的客服电话，将在交易记录中向买家展示，提供咨询服务；2、请确保电话畅通，以便入驻后平台回拨确认。
      </p>
      <vant-field
        label="门店省市编码"
        v-model="
          main.form.business_info.sales_info.biz_store_info.biz_address_code
        "
        :rules="[{ required: true, message: '请填写门店省市编码' }]"
      ></vant-field>
      <vant-field
        label="门店地址"
        rows="1"
        autoszie
        type="textarea"
        placeholder="请输入地址"
        v-model="
          main.form.business_info.sales_info.biz_store_info.biz_store_address
        "
      ></vant-field>
      <vant-field
        name="uploader"
        label="门店门头照片"
        class="uploader-field"
        :rules="[{ required: true, message: '请上传门店门头照片' }]"
      >
        <template #input>
          <vant-uploader
            v-model="
              main.form.business_info.sales_info.biz_store_info
                .store_entrance_pic
            "
            :max-count="1"
          >
            <div class="uploader-input-wrapper">
              <vant-icon name="plus" />
            </div>
          </vant-uploader>
        </template>
      </vant-field>
      <p class="message">
        请上传门店照片（要求门店招牌清晰可见）。若为停车场、售卖机等无固定门头照片
        的经营场所，请提供真实的经营现场照片即可
      </p>
      <vant-field
        name="uploader"
        label="店内环境照片"
        class="uploader-field"
        :rules="[{ required: true, message: '请上传店内环境照片' }]"
      >
        <template #input>
          <vant-uploader
            v-model="
              main.form.business_info.sales_info.biz_store_info.indoor_pic
                .store_entrance_pic
            "
            :max-count="1"
          >
            <div class="uploader-input-wrapper">
              <vant-icon name="plus" />
            </div>
          </vant-uploader>
        </template>
      </vant-field>
      <p class="message">
        请上传门店内部环境照片。若为停车场、售卖机等无固定门头照片的经营场所，请提
        供真实的经营现场照片即可
      </p>
    </div>
    <vant-button
      plain
      type="primary"
      block
      class="confirm-btn"
      @click="handleConfirm"
    >
      下一步
    </vant-button>
    <vant-popup v-model="showCategoryPanel" position="bottom">
      <vant-picker
        show-toolbar
        :columns="categoryList"
        title="选择经营类目"
        @cancel="handleCategoryCancel"
        @confirm="handleCategoryConfirm"
      ></vant-picker>
    </vant-popup>
  </vant-form>
</template>
<script>
import {
  Form as vantForm,
  Field as vantField,
  Uploader as vantUploader,
  Icon as vantIcon,
  Button as vantButton,
  Popup as vantPopup,
  Picker as vantPicker,
  Toast,
} from "vant";
import { SUBJECT_TYPE_LIST, QUALIFICATION_TYPE_MAP } from "../config";
export default {
  components: {
    vantForm,
    vantField,
    vantUploader,
    vantIcon,
    vantButton,
    vantPopup,
    vantPicker,
    Toast,
  },
  inject: ["main"],
  data() {
    return {
      showCategoryPanel: false,
      categoryList: QUALIFICATION_TYPE_MAP[this.main.type],
      subject_type_list: SUBJECT_TYPE_LIST,
      category_rate_message: "",
      qulification_message: "",
    };
  },
  methods: {
    handleConfirm() {
      this.$refs.form
        .validate()
        .then((data) => {
          this.main.gotoNextStep();
        })
        .catch((err) => {
          console.log(err);
          Toast(err[0].message);
        });
    },
    handleCategoryCancel() {
      this.showCategoryPanel = false;
    },
    handleCategoryConfirm(value, index) {
      this.main.form.settlement_info.qualification_type =
        value[value.length - 1];
      this.main.form.settlement_info.settlement_id = this.categoryList[
        index[0]
      ].children[index[1]].value;
      this.category_rate_message = this.categoryList[index[0]].children[
        index[1]
      ].message;
      this.qulification_message = this.categoryList[index[0]].children[
        index[1]
      ].qulification_message;
      this.showCategoryPanel = false;
    },
    handleLicenseRead(files) {
      this.$api
        .licenseInfo({
          file: files.file,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code) {
            if (
              [
                this.subject_type_list.SUBJECT_TYPE_INDIVIDUAL,
                this.subject_type_list.SUBJECT_TYPE_ENTERPRISE,
              ].includes(this.main.type)
            ) {
              this.main.form.subject_info.business_license_info.license_number =
                res.data.data[0].RegNum;
              this.main.form.subject_info.business_license_info.merchant_name =
                res.data.data[0].Name;
              this.main.form.subject_info.business_license_info.legal_person =
                res.data.data[0].Person;
            } else {
              this.main.form.subject_info.certificate_info.cert_number =
                res.data.data[0].RegNum;
              this.main.form.subject_info.certificate_info.merchant_name =
                res.data.data[0].Name;
              this.main.form.subject_info.certificate_info.company_address =
                res.data.data[0].Address;
              this.main.form.subject_info.certificate_info.legal_person =
                res.data.data[0].Persion;
            }
          }
        });
    },
    handleLegalPersonChange(val) {
      this.main.form.bank_account_info.account_name = val;
    }
  },
};
</script>
<style lang="less" scoped>
.van-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.field-list {
  flex: 1;
  padding: 23px 15px 0 15px;
}
.uploader-field {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  &:after {
    content: none;
  }

  /deep/ .van-field__label {
    width: 100%;
  }

  /deep/ .van-field__value {
    margin-top: 15px;

    .van-uploader,
    .van-uploader__wrapper,
    .van-uploader__input-wrapper {
      width: 100%;
    }

    .uploader-input-wrapper {
      width: 100%;
      height: 150px;
      border: 1px solid rgba(144, 143, 143, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(144, 143, 143, 0.5);
      font-size: 20px;
      box-sizing: border-box;
    }
  }

  /deep/ .van-uploader__preview {
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /deep/ .van-uploader__preview-image {
    width: 100%;
    height: 150px;
  }
}

.confirm-btn {
  margin-top: 30px;
  border: 0;
  border-top: 1px solid rgba(144, 143, 143, 0.5);
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  &.van-button--disabled {
    color: rgba(144, 143, 143, 0.5);
  }
}
</style>
