<template>
  <vant-form ref="form">
    <div class="field-list">
      <vant-field
        label="超级管理员姓名"
        v-model="main.form.contact_info.contact_name"
      ></vant-field>
      <vant-field
        type="digit"
        label="超级管理员身份证件号码"
        v-model="main.form.contact_info.contact_id_number"
        :rules="[{ required: true, message: '请填写超级管理员身份证件号码' }]"
      ></vant-field>
      <!-- <vant-field
        label="超级管理员微信openid"
        v-model="main.form.contact_info.openid"
      ></vant-field> -->
      <vant-field
        type="tel"
        label="联系手机"
        v-model="main.form.contact_info.mobile_phone"
        :rules="[{ required: true, message: '请填写手机号码' }]"
      ></vant-field>
      <!-- <vant-field
        type="digit"
        label="短信验证码"
        center
        clearable
        v-model="main.form.contact_info.vertifyCode"
      >
        <template #button>
          <vant-button
            size="small"
            type="primary"
            :disabled="!main.form.contact_info.mobile_phone || isSendingCode"
            @click="handleSendCode"
          >
            {{ sendCodeBtnText }}
          </vant-button>
        </template>
      </vant-field> -->
      <vant-field
        label="联系邮箱"
        v-model="main.form.contact_email"
        :rules="[{ required: true, message: '请填写联系邮箱' }]"
      ></vant-field>
    </div>
    <vant-button
      plain
      type="primary"
      block
      class="confirm-btn"
      @click="handleConfirm"
    >
      完成
    </vant-button>
  </vant-form>
</template>
<script>
import {
  Form as vantForm,
  Field as vantField,
  Button as vantButton,
  Popup as vantPopup,
  Picker as vantPicker,
  Toast,
} from "vant";
import { SUBJECT_TYPE_LIST, ID_DOC_TYPE_MAP } from "../config";
export default {
  components: {
    vantForm,
    vantField,
    vantButton,
    vantPopup,
    vantPicker,
  },
  inject: ["main"],
  data() {
    return {
      isSendingCode: false,
      countdownSeconds: 60,
      sendCodeBtnText: "发送验证码",
      hasSendedCode: false,
    };
  },
  methods: {
    handleSendCode() {
      this.isSendingCode = true;
      if (!this.main.form.contact_info.mobile_phone) {
        return false;
      }
      this.$api
        .sendSms({
          mobile: this.main.form.contact_info.mobile_phone,
        })
        .then((data) => {
          console.log(data);
          if (data.data.code) {
            this.handleCountDown();
          } else {
            this.isSendingCode = false;
            Toast("验证码发送失败，请稍后重试");
          }
        })
        .catch((err) => {
          console.log(err);
          this.isSendingCode = false;
          Toast("网络连接失败，请稍后重试");
        });
    },
    handleCountDown() {
      let countDown = this.hasSendedCode ? 180 : 60;
      this.hasSendedCode = true;

      let timer = setInterval(() => {
        countDown--;
        if (countDown > 0) {
          this.sendCodeBtnText = `${countDown}秒后可重发`;
        } else {
          this.isSendingCode = false;
          this.sendCodeBtnText = "重新发送验证码";
          clearInterval(timer);
        }
      }, 1000);
    },
    handleConfirm() {
      // this.$router.push("/done");
      let form = this.main.form;
      this.$api
        .saveApply({
          contact_name: form.contact_info.contact_name,
          contact_id_number: form.contact_info.contact_id_number,
          // openid: form.contact_info.openid,
          mobile_phone: form.contact_info.mobile_phone,
          // vertifyCode: form.contact_info.vertifyCode,
          contact_email: form.contact_email,
          subject_type: form.subject_info.subject_type,
          ...([
            SUBJECT_TYPE_LIST.SUBJECT_TYPE_INDIVIDUAL,
            SUBJECT_TYPE_LIST.SUBJECT_TYPE_ENTERPRISE,
          ].includes(form.subject_info.subject_type)
            ? {
                merchant_name:
                  form.subject_info.business_license_info.merchant_name,
                legal_person:
                  form.subject_info.business_license_info.legal_person,
                license_number:
                  form.subject_info.business_license_info.license_number,
                license_copy:
                  form.subject_info.business_license_info.license_copy
              }
            : {
                cert_copy: form.subject_info.certificate_info.cert_copy,
                cert_type: form.subject_info.certificate_info.cert_type,
                cert_number: form.subject_info.certificate_info.cert_number,
                merchant_name: form.subject_info.certificate_info.merchant_name,
                company_address:
                  form.subject_info.certificate_info.company_address,
                legal_person: form.subject_info.certificate_info.legal_person,
                period_begin: form.subject_info.certificate_info.period_begin,
                period_end: form.subject_info.certificate_info.period_end,
              }),
          ...([
            SUBJECT_TYPE_LIST.SUBJECT_TYPE_ENTERPRISE,
            SUBJECT_TYPE_LIST.SUBJECT_TYPE_INSTITUTIONS,
            SUBJECT_TYPE_LIST.SUBJECT_TYPE_OTHERS,
          ].includes(form.subject_info.subject_type)
            ? {
                organization_copy:
                  form.subject_info.organization_info.organization_copy,
                organization_code:
                  form.subject_info.organization_info.organization_code,
                org_period_begin:
                  form.subject_info.organization_info.org_period_begin,
                org_period_end:
                  form.subject_info.organization_info.org_period_end
              }
            : {}),
          id_doc_type: form.subject_info.identity_info.id_doc_type,
          ...(form.subject_info.identity_info.id_doc_type ===
          ID_DOC_TYPE_MAP.IDENTIFICATION_TYPE_IDCARD
            ? {
                id_card_copy:
                  form.subject_info.identity_info.id_card_info.id_card_copy,
                id_card_national:
                  form.subject_info.identity_info.id_card_info.id_card_national,
                id_card_name:
                  form.subject_info.identity_info.id_card_info.id_card_name,
                id_card_number:
                  form.subject_info.identity_info.id_card_info.id_card_number,
                card_period_begin:
                  form.subject_info.identity_info.id_card_info
                    .card_period_begin,
                card_period_end:
                  form.subject_info.identity_info.id_card_info.card_period_end,
              }
            : {
                id_doc_copy:
                  form.subject_info.identity_info.id_doc_info.id_doc_copy,
                id_doc_name:
                  form.subject_info.identity_info.id_doc_info.id_doc_name,
                id_doc_number:
                  form.subject_info.identity_info.id_doc_info.id_doc_number,
                doc_period_begin:
                  form.subject_info.identity_info.id_doc_info.doc_period_begin,
                doc_period_end:
                  form.subject_info.identity_info.id_doc_info.doc_period_end,
              }),
          owner: form.subject_info.identity_info.owner,
          ...(form.subject_info.identity_info.owner
            ? {}
            : {
                ubo_info: {
                  id_type: form.subject_info.ubo_info.id_type,
                  id_card_copy: form.subject_info.ubo_info.id_card_copy,
                  id_card_national: form.subject_info.ubo_info.id_card_national,
                  id_doc_copy: form.subject_info.ubo_info.id_doc_copy,
                  name: form.subject_info.ubo_info.name,
                  id_number: form.subject_info.ubo_info.id_number,
                  id_period_begin: form.subject_info.ubo_info.id_period_begin,
                  id_period_end: form.subject_info.ubo_info.id_period_end,
                },
              }),
          merchant_shortname: form.business_info.merchant_name,
          service_phone: form.business_info.service_phone,
          sales_scenes_type: form.business_info.sales_info.sales_scenes_type,
          biz_store_name:
            form.business_info.sales_info.biz_store_info.biz_store_name,
          biz_address_code:
            form.business_info.sales_info.biz_store_info.biz_address_code,
          biz_store_address:
            form.business_info.sales_info.biz_store_info.biz_store_address,
          store_entrance_pic:
            form.business_info.sales_info.biz_store_info.store_entrance_pic,
          indoor_pic: form.business_info.sales_info.indoor_pic,
          settlement_id: form.settlement_info.settlement_id,
          qualification_type: form.settlement_info.qualification_type,
          qualifications: form.settlement_info.qualifications,
          activities_id: form.settlement_info.activities_id,
          activities_rate: form.settlement_info.activities_rate,
          activities_additions: form.settlement_info.activities_additions,
          bank_account_type: form.bank_account_info.bank_account_type,
          account_name: form.bank_account_info.account_name,
          account_bank: form.bank_account_info.account_bank,
          bank_address_code: form.bank_account_info.bank_address_code,
          bank_branch_id: form.bank_account_info.bank_branch_id,
          bank_name: form.bank_account_info.bank_name,
          account_number: form.bank_account_info.account_number,
        })
        .then((data) => {
          console.log(data);
          if (data.data.applyment_id) {
            this.$router.push("/done")
          } else {
            Toast(data.data.msg || data.data.message || "申请提交有误，请检查后再提交");
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.van-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.field-list {
  flex: 1;
  padding: 23px 15px 0 15px;

  /deep/ .title {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    color: rgba(13, 12, 12, 0.4);
  }
}
.confirm-btn {
  margin-top: 30px;
  margin-bottom: 15px;
  border: 1px solid rgba(144, 143, 143, 0.5);
  width: calc(100% - 30px);
  left: 15px;

  &.van-button--disabled {
    color: rgba(144, 143, 143, 0.5);
  }
}
</style>
