<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="progress-wrapper">
        <my-progress :current="currentStep" :steps="totalStep" @step-click="handleStepClick"></my-progress>
      </div>
      <div class="form-content">
        <component :is="componentName"></component>
      </div>
    </div>
  </div>
</template>
<script>
import MyProgress from "@/components/progress";
import Basic from "./steps/basic";
import LegalPerson from "./steps/legal-person";
import BankInfo from "./steps/bank-info";
import SuperAdmin from "./steps/super-admin";
import { STEP_LIST, SUBJECT_TYPE_LIST, SUBJECT_TYPE_MAP, ID_DOC_TYPE_MAP } from "./config";

export default {
  components: {
    MyProgress,
    Basic,
    LegalPerson,
    BankInfo,
    SuperAdmin
  },
  provide() {
    return {
      main: this
    };
  },
  data() {
    return {
      currentStep: 1,
      totalStep: 4,
      type: this.$route.params.type,
      form: {
        contact_info: {
          contact_name: "",  // 超级管理员姓名
          contact_id_number: "", // 超级管理员身份证件号码
          openid: "", // 超级管理员微信openid
          mobile_phone: "", // 联系手机
          vertifyCode: "",
          contact_email: "" // 联系邮箱
        },
        subject_info: {
          // 主体资料
          type: SUBJECT_TYPE_MAP[this.$route.params.type],
          subject_type: this.$route.params.type, // 主体类型 SUBJECT_TYPE_INDIVIDUAL（个体户）、SUBJECT_TYPE_ENTERPRISE（企业）、SUBJECT_TYPE_INSTITUTIONS（党政、机关及事业单位）、SUBJECT_TYPE_OTHERS（其他组织）
          business_license_info: {
            // 营业执照  主体为个体户/企业， 必填。
            merchant_name: "", // 商户名称
            legal_person: "", // 个体户经营者/法人姓名
            license_number: "", // 注册号/统一社会信用代码
            license_copy: [] // 营业执照照片
          },
          certificate_info: {
            // 登记证书  主体为党政、机关及事业单位/其他组织，必填。
            cert_copy: [], // 登记证书照片
            cert_type:
              "" /**  等级证书类型：
                  CERTIFICATE_TYPE_2388：事业单位法人证书
                  CERTIFICATE_TYPE_2389：统一社会信用代码证书
                  CERTIFICATE_TYPE_2390：有偿服务许可证（军队医院适用）
                  CERTIFICATE_TYPE_2391：医疗机构执业许可证（军队医院适用）
                  CERTIFICATE_TYPE_2392：企业营业执照（挂靠企业的党组织适用）
                  CERTIFICATE_TYPE_2393：组织机构代码证（政府机关适用）
                  CERTIFICATE_TYPE_2394：社会团体法人登记证书
                  CERTIFICATE_TYPE_2395：民办非企业单位登记证书
                  CERTIFICATE_TYPE_2396：基金会法人登记证书
                  CERTIFICATE_TYPE_2397：慈善组织公开募捐资格证书
                  CERTIFICATE_TYPE_2398：农民专业合作社法人营业执照
                  CERTIFICATE_TYPE_2399：宗教活动场所登记证
                  CERTIFICATE_TYPE_2400：其他证书/批文/证明
                */,
            cert_number: "", // 证书号
            merchant_name: "", // 商户名称
            company_address: "", // 注册地址
            legal_person: "", // 法人姓名
            period_begin: "", // 有效期限开始日期
            period_end: "" // 有效期限结束日期
          },
          organization_info: {
            // 组织机构代码证  主体为企业/党政、机关及事业单位/其他组织，且证件号码不是18位时必填
            organization_copy: "", // 组织机构代码证照片
            organization_code: "", // 组织机构代码
            org_period_begin: "", // 组织机构代码证有效期开始日期
            org_period_end: "" // 组织机构代码证有效期结束日期
          },
          // certificate_letter_copy: "", //单位证明函照片
          identity_info: {
            id_doc_type_text: "中国大陆居民-身份证",
            // 经营者/法人身份证件
            id_doc_type:
              ID_DOC_TYPE_MAP.IDENTIFICATION_TYPE_IDCARD /* 证件类型：
                IDENTIFICATION_TYPE_IDCARD：中国大陆居民-身份证
                IDENTIFICATION_TYPE_OVERSEA_PASSPORT：其他国家或地区居民-护照
                IDENTIFICATION_TYPE_HONGKONG_PASSPORT：中国香港居民-来往内地通行证
                IDENTIFICATION_TYPE_MACAO_PASSPORT：中国澳门居民-来往内地通行证dd
                IDENTIFICATION_TYPE_TAIWAN_PASSPORT：中国台湾居民-来往大陆通行证
              */,
            id_card_info: {
              // 身份证信息
              id_card_copy: "", // 身份证人像面照片
              id_card_national: "", // 身份证国徽面照片
              id_card_name: "", // 身份证姓名
              id_card_number: "", // 身份证号码
              card_period_begin: "", // 身份证有效期开始时间
              card_period_end: "" // 身份证有效期结束时间
            },
            id_doc_info: {
              // 其他类型证件信息
              id_doc_copy: "", // 证件照片
              id_doc_name: "", // 证件姓名
              id_doc_number: "", // 证件号码
              doc_period_begin: "", // 证件有效期开始时间
              doc_period_end: "" // 证件有效期结束时间
            },
            owner: true // 经营者/法人是否为受益者
          },
          ubo_info: {
            // 最终受益人
            id_type:
              "" /* 证件类型：
                IDENTIFICATION_TYPE_IDCARD：中国大陆居民-身份证
                IDENTIFICATION_TYPE_OVERSEA_PASSPORT：其他国家或地区居民-护照
                IDENTIFICATION_TYPE_HONGKONG_PASSPORT：中国香港居民-来往内地通行证
                IDENTIFICATION_TYPE_MACAO_PASSPORT：中国澳门居民-来往内地通行证dd
                IDENTIFICATION_TYPE_TAIWAN_PASSPORT：中国台湾居民-来往大陆通行证
              */,
            id_card_copy: "", // 身份证人像面照片
            id_card_national: "", // 身份证国徽面照片
            id_doc_copy: "", // 证件照片
            name: "", // 受益人姓名
            id_number: "", // 证件号码
            id_period_begin: "", // 证件有效期开始时间
            id_period_end: "" // 证件有效期结束时间
          }
        },
        business_info: {
          // 经营资料
          merchant_shortname: "", // 商户简称
          service_phone: "", // 客服电话
          sales_info: {
            // 经营场景
            sales_scenes_type: ["SALES_SCENES_STORE"] /** 经营场景类型
              SALES_SCENES_STORE：线下门店
              SALES_SCENES_MP：公众号
              SALES_SCENES_MINI_PROGRAM：小程序
              SALES_SCENES_WEB：互联网
              SALES_SCENES_APP：APP
              SALES_SCENES_WEWORK：企业微信
             */,
            biz_store_info: {
              // 线下门店场景
              biz_store_name: "", // 门店名称
              biz_address_code: "", // 门店省市编码
              biz_store_address: "", // 门店地址
              store_entrance_pic: [], // 门店门头照片
              indoor_pic: [], // 店内环境照片
              // biz_sub_appid: "" // 线下场所对应的商家APPID
            },
            mp_info: {
              // 公众号场景
              mp_appid: "", // 服务商公众号APPID
              mp_sub_appid: "", // 商家公众号
              mp_pics: [] // 公众号页面截图
            },
            mini_program_info: {
              // 小程序场景
              mini_program_appid: "", // 服务商小程序APPID
              mini_program_sub_appid: "", // 商家小程序APPID
              mini_program_pics: [] // 小程序截图
            },
            app_info: {
              // APP场景
              app_appid: "", // 服务商应用APPID
              app_sub_appid: "", // 商家应用APPID
              app_pics: "" // APP截图
            },
            web_info: {
              // 互联网网站场景
              domain: "", // 互联网网站域名
              web_authorisation: "", // 网站授权函
              web_appid: "" // 互联网网站对应的商家APPID
            },
            wework_info: {
              // 企业微信场景
              sub_corp_id: "", // 商家企业微信CorpID
              wework_pics: "" // 企业微信页面截图
            }
          }
        },
        settlement_info: {
          // 结算规则
          settlement_id: "", // 入驻结算规则ID
          qualification_type: "", // 所属行业
          qualifications: [], // 特殊资质图片
          activities_id: "", // 优惠费率活动ID
          activities_rate: "", // 优惠费率活动值
          activities_additions: [] // 优惠费率活动补充材料
        },
        bank_account_info: {
          // 结算银行账户
          bank_account_type: "BANK_ACCOUNT_TYPE_CORPORATE", // 账户类型 BANK_ACCOUNT_TYPE_CORPORATE：对公银行账户、BANK_ACCOUNT_TYPE_PERSONAL：经营者个人银行卡
          account_name: "", // 开户名称
          account_bank: "", // 开户银行
          bank_address_code: "", // 开户银行省市编码
          bank_branch_id: "", // 开户银行联行号
          bank_name: "", // 开户银行全称
          account_number: "" // 银行账号
        },
        // addition_info: {
        //   // 补充材料
        //   legal_person_commitment: "", // 法人开户承诺函
        //   legal_person_video: "", // 法人开户意愿视频
        //   business_addition_pics: [], // 补充材料
        //   business_addition_msg: "" // 补充说明
        // }
      }
    };
  },
  computed: {
    componentName() {
      return {
        1: "basic",
        2: "legalPerson",
        3: "bankInfo",
        4: "superAdmin"
      }[this.currentStep];
    }
  },
  methods: {
    gotoNextStep() {
      if (this.currentStep < this.totalStep) {
        this.currentStep += 1;
      }
    },
    handleStepClick(step) {
      this.currentStep = step;
    }
  },
  mounted() {
    this.form.subject_info.certificate_info.cert_type = {
      [`${SUBJECT_TYPE_LIST.SUBJECT_TYPE_INSTITUTIONS}`]: "CERTIFICATE_TYPE_2388",
      [`${SUBJECT_TYPE_LIST.SUBJECT_TYPE_OTHERS}`]: "CERTIFICATE_TYPE_2395"
    }[this.type];
  },
  watch: {
    currentStep: {
      immediate: true,
      handler(val) {
        document.body.title = STEP_LIST[val];
        document.documentElement.scrollTop = 0;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.page-wrapper {
  background: #5aa16c;
  padding: 0 10px 17px 10px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  box-sizing: border-box;

  .page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .progress-wrapper {
    padding: 27px 30px;
  }

  .form-content {
    background: #ffffff;
    border-radius: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>