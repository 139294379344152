<template>
  <vant-form ref="form">
    <div class="field-list">
      <vant-field
        v-model="bank_account_type"
        label="账户类型"
        readonly
      ></vant-field>
      <vant-field
        label="银行账号"
        type="digit"
        v-model="main.form.bank_account_info.account_number"
        :rules="[{ required: true, message: '请填写银行账号' }]"
      >
        <template #button>
          <vant-uploader :after-read="handleBankCardRead">
            <vant-icon name="photograph"></vant-icon>
          </vant-uploader>
        </template>
      </vant-field>
      <vant-field
        v-model="main.form.bank_account_info.account_name"
        label="开户名称"
        :rules="[{ required: true, message: '请填写开户名称' }]"
      ></vant-field>
      <p class="message">
        为确保交易资金安全，银行账户开户名称需与营业执照登记名称一致；若有误，可返回相应的填写页面修改
      </p>
      <vant-field
        label="开户银行"
        v-model="main.form.bank_account_info.account_bank"
        clickable
        is-link
        @click="showBankPanel = true"
        :rules="[{ required: true, message: '请选择开户银行' }]"
      ></vant-field>
      <vant-field
        label="开户银行省市编码"
        type="digit"
        v-model="main.form.bank_account_info.bank_address_code"
        :rules="[{ required: true, message: '请填写开户银行省市编码' }]"
      ></vant-field>
      <template v-if="main.form.bank_account_info.account_bank === '其他银行'">
        <!-- <vant-field
          label="开户银行联行号"
          v-model="main.form.bank_account_info.bank_branch_id"
        ></vant-field> -->
        <vant-field
          label="开户银行全称（含支行）"
          v-model="main.form.bank_account_info.bank_name"
          :rules="[{ required: true, message: '请填写开户银行全称' }]"
        ></vant-field>
      </template>
    </div>
    <vant-button
      plain
      type="primary"
      block
      class="confirm-btn"
      @click="handleConfirm"
      >下一步</vant-button
    >
    <vant-popup v-model="showBankPanel" position="bottom">
      <vant-picker
        show-toolbar
        :columns="bank_list"
        :default-index="currentBankIndex"
        @confirm="handleBankConfirm"
        @cancel="showBankPanel = false"
      ></vant-picker>
    </vant-popup>
  </vant-form>
</template>
<script>
import {
  Form as vantForm,
  Field as vantField,
  Uploader as vantUploader,
  Icon as vantIcon,
  Button as vantButton,
  Popup as vantPopup,
  Picker as vantPicker,
  Toast,
} from "vant";

import { BANK_LIST, BANK_ACCOUNT_TYPE_LIST } from "../config";

export default {
  components: {
    vantForm,
    vantField,
    vantUploader,
    vantIcon,
    vantButton,
    vantPopup,
    vantPicker,
    Toast,
  },
  inject: ["main"],
  data() {
    return {
      bank_account_type_list: BANK_ACCOUNT_TYPE_LIST,
      showBankPanel: false,
      bank_list: BANK_LIST,
      currentBankIndex: 0,
    };
  },
  computed: {
    bank_account_type() {
      return this.bank_account_type_list.filter(
        (item) =>
          item.value === this.main.form.bank_account_info.bank_account_type
      )[0].text;
    },
  },
  methods: {
    handleBankConfirm(value) {
      this.main.form.bank_account_info.account_bank = value;
      this.showBankPanel = false;
    },
    handleConfirm() {
      this.$refs.form
        .validate()
        .then((data) => {
          this.main.gotoNextStep();
        })
        .catch((err) => {
          Toast(err[0].message);
        });
    },
    handleBankCardRead(files) {
      this.$api
        .bankInfo({
          file: files.file,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code) {
            this.main.form.bank_account_info.account_number =
              res.data.data[0].CardNo;
            let bankName = res.data.data[0].BankInfo.match(/^[^(]+/)[0];
            this.main.form.bank_account_info.account_bank = bankName || "";
            this.currentBankIndex =
              this.bank_list.indexOf(bankName) > -1
                ? this.bank_list.indexOf(bankName)
                : 0;
          } else {
            this.$message({
              type: "error",
              message: "银行卡识别失败",
            });
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.van-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.field-list {
  padding: 23px 15px 0 15px;
  flex: 1;
  overflow: hidden;
}

.confirm-btn {
  margin-top: 30px;
  border: 0;
  border-top: 1px solid rgba(144, 143, 143, 0.5);
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  &.van-button--disabled {
    color: rgba(144, 143, 143, 0.5);
  }
}
</style>
