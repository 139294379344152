<template>
  <div class="step-wrapper">
    <div
      :class="['step-item', { 'is-active': step === current }]"
      v-for="step in steps"
      :key="step"
      @click="handleStepItemClick(step)"
    >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    steps: {
      type: [Number, String],
      default: 4
    },
    current: {
      type: [Number, String],
      default: 1
    }
  },
  methods: {
    handleStepItemClick(step) {
      this.$emit("step-click", step)
    }
  }
};
</script>
<style lang="less" scoped>
.step-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #3B6946;
  }

  .step-item {
    width: 8px;
    height: 8px;
    background: #3B6946;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;

    &.is-active {
      width: 12px;
      height: 12px;
      background: #5AA16C;
      border: 1px solid #FFFFFF;
    }
  }
}
</style>