<template>
  <vant-form ref="form">
    <div class="field-list">
      <card-info
        title="法人/经营者信息"
        @change="handleIdentityInfoChange"
      ></card-info>
      <vant-field
        label="是否为受益人"
        readonly
        clickable
        is-link
        :value="isOwner"
        input-align="right"
        @click="showOwnerPanel = true"
      ></vant-field>
      <card-info
        title="受益人信息"
        style="margin-top: 10px;"
        v-if="!main.form.subject_info.identity_info.owner"
        @change="handleUboInfoChange"
      ></card-info>
    </div>
    <vant-button
      plain
      type="primary"
      block
      class="confirm-btn"
      @click="handleConfirm"
    >
      下一步
    </vant-button>
    <vant-popup v-model="showOwnerPanel" position="bottom">
      <vant-picker
        show-toolbar
        :columns="isOwnerList"
        :default-index="defaultOwnerIndex"
        @cancel="showOwnerPanel = false"
        @confirm="handleOwnerChooseConfirm"
      ></vant-picker>
    </vant-popup>
  </vant-form>
</template>
<script>
import {
  Form as vantForm,
  Field as vantField,
  Button as vantButton,
  Popup as vantPopup,
  Picker as vantPicker,
  Toast,
} from "vant";
import CardInfo from "../card-info";
import { ID_DOC_TYPE_MAP } from "../config";
export default {
  components: {
    vantForm,
    vantField,
    vantButton,
    vantPopup,
    vantPicker,
    CardInfo,
  },
  inject: ["main"],
  data() {
    return {
      showPaperTypePanel: false,
      showOwnerPanel: false,
      isOwnerList: [
        {
          text: "是",
          value: true,
        },
        {
          text: "否",
          value: false,
        },
      ],
    };
  },
  computed: {
    isOwner() {
      return this.main.form.subject_info.identity_info.owner ? "是" : "否";
    },
    defaultOwnerIndex() {
      return this.isOwnerList.map((item) => item.text).indexOf(this.isOwner);
    },
  },
  methods: {
    handleIdentityInfoChange(data) {
      this.main.form.subject_info.identity_info = {
        ...this.main.form.subject_info.identity_info,
        id_doc_type: data.doc_type,
        ...(data.doc_type === ID_DOC_TYPE_MAP.IDENTIFICATION_TYPE_IDCARD
          ? {
              id_card_info: {
                id_card_copy: data.card_info.card_copy,
                id_card_national: data.card_info.card_national,
                id_card_name: data.card_info.card_name,
                id_card_number: data.card_info.card_number,
                card_period_begin: data.card_info.card_period_begin,
                card_period_end: data.card_info.card_period_end,
              },
            }
          : {
              id_doc_info: {
                id_doc_copy: data.card_info.card_copy,
                id_doc_name: data.card_info.card_name,
                id_doc_number: data.card_info.card_number,
                doc_period_begin: data.card_info.card_period_begin,
                doc_period_end: data.card_info.card_period_end,
              },
            }),
      };
    },
    handleUboInfoChange(data) {
      this.main.form.subject_info.ubo_info = {
        ...this.main.form.subject_info.ubo_info,
        id_type: data.type,
        name: data.card_info.card_nmae,
        id_number: data.card_info.card_number,
        id_period_begin: data.card_info.card_period_begin,
        id_period_end: data.card_info.card_period_end,
        ...(data.type === ID_DOC_TYPE_MAP.IDENTIFICATION_TYPE_IDCARD
          ? {
              id_card_copy: data.card_info.card_copy,
              id_card_national: data.card_info.card_copy,
            }
          : {
              id_doc_copy: data.card_info.card_copy,
            }),
      };
    },
    handleConfirm() {
      this.$refs.form
        .validate()
        .then(() => {
          this.main.gotoNextStep();
        })
        .catch((err) => {
          console.log(err);
          Toast(err[0].message);
        });
    },
    handleOwnerChooseConfirm(item) {
      this.showOwnerPanel = false;
      this.main.form.subject_info.identity_info.owner = item.value;
    },
  },
};
</script>
<style lang="less" scoped>
.field-list {
  padding: 23px 15px 0 15px;

  /deep/ .title {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    color: rgba(13, 12, 12, 0.4);
  }
}
.confirm-btn {
  margin-top: 30px;
  border: 0;
  border-top: 1px solid rgba(144, 143, 143, 0.5);
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  &.van-button--disabled {
    color: rgba(144, 143, 143, 0.5);
  }
}
</style>
