<template>
  <div>
    <label class="title">{{ title }}</label>
    <vant-field
      :value="doc_type_text"
      label="证件类型"
      readonly
      clickable
      is-link
      @click="showDocTypePanel = true"
    ></vant-field>
    <vant-field
      name="uploader"
      :label="isIDCard ? '身份证人像面照片' : '证件照片'"
      class="uploader-field"
      :rules="[
        {
          required: true,
          message: '请上传' + isIDCard ? '身份证人像面照片' : '证件照片',
        },
      ]"
    >
      <template #input>
        <vant-uploader
          v-model="form.card_info.card_copy"
          :max-count="1"
          :after-read="handleIDCardRead"
        >
          <div class="uploader-input-wrapper">
            <vant-icon name="plus" />
          </div>
        </vant-uploader>
      </template>
    </vant-field>
    <p class="message" v-if="isIDCard">
      即身份证正面照片（包含姓名、公民身份号码）
    </p>
    <template v-if="isIDCard">
      <vant-field
        name="uploader"
        label="身份证国徽面照片"
        class="uploader-field"
        :rules="[{ required: true, message: '请上传身份证国徽面照片' }]"
      >
        <template #input>
          <vant-uploader
            v-model="form.card_info.card_national"
            :max-count="1"
            :after-read="handleIDCardRead"
          >
            <div class="uploader-input-wrapper">
              <vant-icon name="plus" />
            </div>
          </vant-uploader>
        </template>
      </vant-field>
      <template
        v-if="form.card_info.card_copy && form.card_info.card_copy.length"
      >
        <vant-field
          label="身份证姓名"
          v-model="form.card_info.card_name"
          :rules="[{ required: true, message: '请填写身份证姓名' }]"
        ></vant-field>
        <vant-field
          label="身份证号码"
          v-model="form.card_info.card_number"
          :rules="[{ required: true, message: '请填写身份证号码' }]"
        ></vant-field>
      </template>
      <template
        v-if="
          form.card_info.card_national && form.card_info.card_national.length
        "
      >
        <vant-field
          label="期限类型"
          :value="period_type"
          readonly
          clickable
        ></vant-field>
        <vant-field
          label="身份证有效期开始时间"
          readonly
          clickable
          name="datetimePicker"
          :value="period_start_date"
          @click="showPeriodBeginPicker = true"
          :rules="[{ required: true, message: '请选择身份证有效期开始时间' }]"
        >
        </vant-field>
        <vant-field
          label="身份证有效期结束时间"
          readonly
          clickable
          name="datetimePicker"
          :value="period_end_date"
          @click="showPeriodEndPicker = true"
          :rules="[{ required: true, message: '请选择身份证有效期结束时间' }]"
          v-if="isLimitPeriod"
        >
        </vant-field>
        <vant-field
          label="身份证有效期结束时间"
          readonly
          :value="period_end_date"
          :rules="[{ required: true, message: '请选择身份证有效期结束时间' }]"
          v-else
        ></vant-field>
      </template>
    </template>
    <template v-else>
      <template
        v-if="form.card_info.card_copy && form.card_info.card_copy.length"
      >
        <vant-field
          label="证件姓名"
          v-model="form.card_info.card_name"
          :rules="[{ required: true, message: '请填写证件姓名' }]"
        ></vant-field>
        <vant-field
          label="证件号码"
          v-model="form.card_info.card_number"
          :rules="[{ required: true, message: '请填写证件号码' }]"
        ></vant-field>
        <vant-field
          label="期限类型"
          :value="period_type"
          readonly
          clickable
        ></vant-field>
        <vant-field
          label="证件有效期开始时间"
          readonly
          clickable
          name="datetimePicker"
          :value="period_start_date"
          @click="showPeriodBeginPicker = true"
          :rules="[{ required: true, message: '请选择证件有效期开始时间' }]"
        ></vant-field>
        <vant-field
          label="证件有效期结束时间"
          readonly
          clickable
          name="datetimePicker"
          :value="period_end_date"
          @click="showPeriodEndPicker = true"
          :rules="[{ required: true, message: '请选择证件有效期结束时间' }]"
          v-if="isLimitPeriod"
        ></vant-field>
        <vant-field
          label="身份证有效期结束时间"
          readonly
          :value="period_end_date"
          v-else
        ></vant-field>
      </template>
    </template>
    <vant-popup v-model="showDocTypePanel" position="bottom">
      <vant-picker
        show-toolbar
        :columns="docTypeList"
        title="选择证件类型"
        @cancel="handleDocTypeChooseCancel"
        @confirm="handleDocTypeChooseConfirm"
      ></vant-picker>
    </vant-popup>
    <vant-popup v-model="showPeriodBeginPicker" position="bottom">
      <vant-datetime-picker
        v-model="form.card_info.card_period_begin"
        type="date"
        :min-date="startDate"
        :formatter="formatter"
        @confirm="handlePeriodBeginConfirm"
        @cancel="handlePeriodBeginCancel"
      ></vant-datetime-picker>
    </vant-popup>
    <vant-popup v-model="showPeriodEndPicker" position="bottom">
      <vant-datetime-picker
        v-model="form.card_info.card_period_end"
        type="date"
        :min-date="startDate"
        :formatter="formatter"
        @confirm="handlePeriodEndConfirm"
        @cancel="handlePeriodEndCancel"
      ></vant-datetime-picker>
    </vant-popup>
  </div>
</template>
<script>
import {
  Field as vantField,
  Uploader as vantUploader,
  Icon as vantIcon,
  Popup as vantPopup,
  Picker as vantPicker,
  DatetimePicker as vantDatetimePicker,
} from "vant";
import { ID_DOC_TYPE_MAP, ID_DOC_TYPE_LIST } from "./config";
import dayjs from "dayjs";

export default {
  components: {
    vantField,
    vantUploader,
    vantIcon,
    vantPopup,
    vantPicker,
    vantDatetimePicker,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        doc_type: ID_DOC_TYPE_MAP.IDENTIFICATION_TYPE_IDCARD,
        card_info: {
          card_copy: [],
          card_national: [],
          card_name: "",
          card_number: "",
          card_period_begin: "",
          card_period_end: "",
        },
      },
      docTypeList: ID_DOC_TYPE_LIST,
      docTypeMap: ID_DOC_TYPE_MAP,
      showDocTypePanel: false,
      isLimitPeriod: true,
      showPeriodBeginPicker: false,
      showPeriodEndPicker: false,
      startDate: new Date(1920, 0, 1),
    };
  },
  computed: {
    isIDCard() {
      return this.form.doc_type === this.docTypeMap.IDENTIFICATION_TYPE_IDCARD;
    },
    doc_type_text() {
      return ID_DOC_TYPE_LIST.filter(
        (item) => item.value === this.form.doc_type
      )[0].text;
    },
    period_type() {
      return this.isLimitPeriod ? "非长期有效" : "长期有效";
    },
    period_start_date() {
      return this.form.card_info.card_period_begin
        ? dayjs(this.form.card_info.card_period_begin).format("YYYY-MM-DD")
        : "";
    },
    period_end_date() {
      return this.isLimitPeriod
        ? this.form.card_info.card_period_end
          ? dayjs(this.form.card_info.card_period_end).format("YYYY-MM-DD")
          : ""
        : "长期";
    },
  },
  methods: {
    handleDocTypeChooseCancel() {
      this.showDocTypePanel = false;
    },
    handleDocTypeChooseConfirm(item) {
      this.showDocTypePanel = false;
      this.form.doc_type = item.value;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
    },
    handlePeriodBeginConfirm(value) {
      this.form.card_info.card_period_begin = value;
      this.showPeriodBeginPicker = false;
    },
    handlePeriodBeginCancel(value) {
      this.showPeriodBeginPicker = false;
    },
    handlePeriodEndConfirm(value) {
      this.showPeriodEndPicker = false;
      this.form.card_info.card_period_end = value;
    },
    handlePeriodEndCancel() {
      this.showPeriodEndPicker = false;
    },
    handleIDCardRead(files) {
      console.log(files);
      if (this.form.doc_type === ID_DOC_TYPE_MAP.IDENTIFICATION_TYPE_IDCARD) {
        this.$api
          .idcardInfo({
            file: files.file,
          })
          .then((res) => {
            if (res.data.code) {
              let form = {};
              let info = res.data.data[0];
              if (info.Name) {
                form.card_name = info.Name;
              }
              if (info.IdNum) {
                form.card_number = info.IdNum;
              }
              if (info.ValidDate) {
                let dateRange = info.ValidDate.split("-");
                form.card_period_begin = dayjs(new Date(dateRange[0])).format(
                  "YYYY-MM-DD"
                );
                form.card_period_end = dayjs(new Date(dateRange[1])).format(
                  "YYYY-MM-DD"
                );
              }
              this.form.card_info = {
                ...this.form.card_info,
                ...form,
              };
            }
          });
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>
